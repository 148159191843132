import React, { useState } from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 8px;
`;

const StyledFormContainer = styled.div`
  width: 500px; /* 원하는 가로 길이 설정 (500px) */
  margin: 20px auto; /* 상하 여백 20px, 좌우 가운데 정렬 */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const GreetingText = styled.h3`
  text-align: center; /* 텍스트 가운데 정렬 */
  color: #333; /* 텍스트 색상 설정 */
  margin-bottom: 20px; /* 아래쪽 여백 설정 */
`;


const ConsultationForm = () => {
  const [consultationResponseMessage, setConsultationResponseMessage] = useState('');

  // 로컬 API URL 설정
  const apiUrl = 'http://localhost:8000';
  const consultationUrl = `${apiUrl}/consultation-request`;

  // 기장 상담 요청 함수 
  const handleConsultationRequest = async (e) => {
    e.preventDefault();
    const form = e.target;

    const requestData = {
      name1: form.name1.value,
      name: form.name.value,
      saup: form.saup.value,
      phone: form.phone.value,
      consultationContent: form.consultationContent.value,
    };

    try {
      const response = await fetch(consultationUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      setConsultationResponseMessage(data.message); // 서버에서 받은 메시지 설정
    } catch (error) {
      console.error('Error:', error);
      setConsultationResponseMessage('상담 요청 중 오류가 발생했습니다.'); // 오류 메시지 설정
    }
  };

  return (
    <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <GreetingText>기장상담요청을 원하시는 경우 아래 폼을 작성해주시면 <br />
        빠른 시일 내에 확인하여 연락드리겠습니다.</GreetingText> {/* "안녕하세요" 텍스트 추가 */}
        <br />
        <StyledFormContainer>
        <h2>기장상담요청</h2>
        <form onSubmit={handleConsultationRequest}>
            <StyledLabel>이름:</StyledLabel>
            <input type="text" name="name1" placeholder="홍길동" required />
            <br />
            <StyledLabel>상호:</StyledLabel>
            <input type="text" name="name" placeholder="상호" required />
            <br />
            <StyledLabel>사업자등록번호:</StyledLabel>
            <input type="text" name="saup" placeholder="123-45-67890" required />
            <br />
            <StyledLabel>핸드폰번호:</StyledLabel>
            <input type="text" name="phone" placeholder="010-1234-5678" required />
            <br />
            <br />
            <StyledTextarea name="consultationContent" placeholder="상담내용" required></StyledTextarea>
            <br />
            <br />
            <button type="submit">상담요청</button>
        </form>
        {consultationResponseMessage && <p>{consultationResponseMessage}</p>}
        </StyledFormContainer>
        <br />
        <br />
        <br />
        <br />
        <br />
    </>
  );
};

export default ConsultationForm;
