import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import KakaoMap from './components/KakaoMap';
import LogoCollection from './components/LogoCollection';
import Highlights from './components/Highlights';
import Pricing from './components/Pricing';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import AppTheme from '../shared-theme/AppTheme';
import ConsultationForm from './components/ConsultationForm';
import TaxRequestForm from './components/TaxRequestForm';

export default function MarketingPage(props) {
  // Hero 섹션에 대한 참조 생성
  const heroRef = React.useRef(null);
  // FAQ 섹션에 대한 참조 생성
  const faqRef = React.useRef(null);
  // Highlights 섹션에 대한 참조 생성
  const highlightsRef = React.useRef(null);
  // ConsultationForm 섹션에 대한 참조 생성
  const ConsultationFormRef = React.useRef(null);
  // TaxRequestForm 섹션에 대한 참조 생성
  const TaxRequestFormRef = React.useRef(null);


  // Hero 섹션으로 스크롤 이동하는 함수
  const scrollToHero = () => {
    heroRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // FAQ 섹션으로 스크롤 이동하는 함수
  const scrollToFAQ = () => {
    faqRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // Highlights 섹션으로 스크롤 이동하는 함수
  const scrollToHighlights = () => {
    highlightsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // ConsultationForm 섹션으로 스크롤 이동하는 함수
  const scrollToConsultationForm = () => {
    ConsultationFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // ConsultationForm 섹션으로 스크롤 이동하는 함수
  const scrollToTaxRequestForm = () => {
    TaxRequestFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };



  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar scrollToHero={scrollToHero} scrollToFAQ={scrollToFAQ} scrollToHighlights={scrollToHighlights}  scrollToConsultationForm={scrollToConsultationForm} scrollToTaxRequestForm={scrollToTaxRequestForm} /> {/* 함수들을 AppAppBar에 전달 */}
      <div ref={heroRef}> {/* Hero 섹션에 ref 추가 */}
        <Hero />
      </div>
      <KakaoMap />
      <div>
        {/* <LogoCollection /> */}
        {/* <Features /> */}
        {/* <Divider /> */}
        {/* <Testimonials />
        <Divider /> */}        
        <div ref={highlightsRef}> {/* Highlights 섹션에 ref 추가 */}
          <Highlights />
        </div>
        <Divider />
        {/* <Pricing /> */}
        <div ref={ConsultationFormRef}>
          <ConsultationForm />
        </div>
        <Divider />
        <div ref={TaxRequestFormRef}>
          <TaxRequestForm />
        </div>
        <Divider />
        <div ref={faqRef}> {/* FAQ 섹션에 ref 추가 */}
          <FAQ />
        </div>
        <Divider />
        <Footer />
      </div>
    </AppTheme>
  );
}
