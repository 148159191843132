import React from 'react';
import { Map, MapMarker } from 'react-kakao-maps-sdk';


export default function KakaoMap() {
  return (
    // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
       
      <Map
        center={{ lat: 37.214828273747564, lng: 127.09990915126446 }}
        style={{ width: "640px", height: "360px" }}
      >
        
        <MapMarker position={{ lat: 37.214828273747564, lng: 127.09990915126446 }}>
          <div style={{ color: "#000" }}>리앤유 세무회계</div>
        </MapMarker>
      </Map>
      
      {/* 여백 추가 */}
      <div style={{ height: '150px' }} /> {/* 빈 div 추가 */}
    </div>

  );
}
