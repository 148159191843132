import React, { useState } from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
  display: block;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  margin-top: 8px;
`;

const StyledFormContainer = styled.div`
  width: 500px; /* 원하는 가로 길이 설정 (500px) */
  margin: 20px auto; /* 상하 여백 20px, 좌우 가운데 정렬 */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

const GreetingText = styled.h3`
  text-align: center; /* 텍스트 가운데 정렬 */
  color: #333; /* 텍스트 색상 설정 */
  margin-bottom: 20px; /* 아래쪽 여백 설정 */
`;

const TaxRequestForm = () => {
  const [taxResponseMessage, setTaxResponseMessage] = useState('');

  // 로컬 API URL 설정
  const apiUrl = 'http://localhost:8000';
  const correctionUrl = `${apiUrl}/correction-request`;

  // 경정청구 요청 함수 
  const handleTaxRequest = async (e) => {
    e.preventDefault();
    const form = e.target;

    const requestData = {
      birthday: form.birthday.value,
      name1: form.name1.value,
      phone: form.phone.value,
      name: form.name.value,
      saup: form.saup.value,
      tax: form.tax.value,
      taxyear: form.taxyear.value,
    };

    try {
      const response = await fetch(correctionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      setTaxResponseMessage(data.message); // 서버에서 받은 메시지 설정
    } catch (error) {
      console.error('Error:', error);
      setTaxResponseMessage('경정청구 요청 중 오류가 발생했습니다.'); // 오류 메시지 설정
    }
  };

  return (
    <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <GreetingText>종합소득세에 대한 경정청구를 요청하시는 경우  <br />
        아래 폼을 작성해주시면 빠른 시일 내에 확인하여 연락드리겠습니다.</GreetingText> {/* "안녕하세요" 텍스트 추가 */}
        <br />
        <StyledFormContainer>        
        <h2>종합소득세 경정청구 신청하기</h2>
        <form onSubmit={handleTaxRequest}>
            <StyledLabel>주민등록번호:</StyledLabel>
            <input type="text" name="birthday" placeholder="9001011234567" required />
            <br />
            <StyledLabel>이름:</StyledLabel>
            <input type="text" name="name1" placeholder="홍길동" required />
            <br />
            <StyledLabel>핸드폰번호:</StyledLabel>
            <input type="text" name="phone" placeholder="01012345678" required />
            <br />
            <StyledLabel>상호:</StyledLabel>
            <input type="text" name="name" placeholder="상호" required />
            <br />
            <StyledLabel>사업자등록번호:</StyledLabel>
            <input type="text" name="saup" placeholder="1234567890" required />
            <br />
            <StyledLabel>세목:</StyledLabel>
            <input type="text" name="tax" placeholder="종합소득세" required />
            <br />
            <StyledLabel>연도:</StyledLabel>
            <input type="text" name="taxyear" placeholder="2023" required />
            <br />
            <br />
            <button type="submit">카카오톡 인증하고 국세청 신고자료 보내기</button>
            <br />    
            <br />          
            국세청에서 자료를 받아오는데 시간이 소요됩니다.<br />    
            아래에 완료메시지가 뜰 때까지 기다려주세요.
        </form>
        {taxResponseMessage && <p>{taxResponseMessage}</p>}
        </StyledFormContainer>
        <br />
        <br />
        <br />
        <br />
        <br />
    </>
  );
};

export default TaxRequestForm;
