import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: '세금신고',
    description:
      '원천세, 종합소득세, 부가가치세, 법인세 등 세금신고업무를 제공해드립니다.',
      
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: '지급명세서 제출',
    description:
      '간이지급명세서, 지급명세서 제출 등의 업무를 제공해드립니다',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: '4대보험 업무',
    description:
      '사업장 가입, 근로자 취득신고/상실신고, 소득총액 신고, 보수총액 신고 등 4대보험 업무를 제공해드립니다.',
  },  
  {
    icon: <AutoFixHighRoundedIcon />,
    title: '일정관리',
    description:
      '각 신고 업무에 대한 자료요청을 미리 안내드리고 바쁘셔서 피드백을 주지 못하시더라도 누락되지 않도록 주기적으로 재요청드리며 예정고지 등 놓치기 쉬운 고지내역 일정도 관리해드립니다. ',
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: '세무계획 수립',
    description:
      '상반기 데이터를 토대로 1년치 매출/매입을 추정하여 종합소득세 및 법인세 신고 시 납부할 세액을 미리 산출하고, 이에 대해 사업자분들께 보고 및 방향성에 대하여 의논을 진행하고 있습니다.',
  },
  {
    icon: <QueryStatsRoundedIcon />,
    title: '합리적인 기장료',
    description:
      '자체프로그램을 개발하여 전반적인 스케줄링을 자동화하였기 때문에 영세한 개입사업장 및 1인 법인사업장 등에 합리적인 기장료를 책정할 수 있게 되었습니다. 매출액이 일정금액 미만이거나 직원이 없는 사업자분들께서는 합리적인 기장료로 이용하실 수 있습니다.',
  },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        // color: 'white',
        // bgcolor: 'grey.900',
        color: 'grey.900',
        bgcolor: 'white',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            리앤유 세무회계에서 하는 일
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            리앤유 세무회계에서는 기장계약 거래처분들에게 아래와 같은 업무를 제공해드립니다.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  // color: 'inherit',
                  color: 'white',
                  p: 3,
                  height: '100%',
                  borderColor: 'hsla(220, 25%, 25%, 0.3)',
                  backgroundColor: 'grey.800',
                }}
              >
                <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                <div>
                  <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
